import React, { useState, useContext } from 'react'
import Login from './Login.js'
import ServiceSelect from './ServiceSelect.js'
import AuthContext from "../context/AuthProvider"

const Landing = () => {
    const { auth } = useContext(AuthContext);
    return (
        <>{ auth === true ? (<ServiceSelect/>) : <Login/>}</>
    )
}

export default Landing