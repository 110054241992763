import { Outlet, Link } from "react-router-dom";
import {useEffect} from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import AuthContext from "../context/AuthProvider"
import Menu from './Menu'
import Login from './Login'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


library.add(fas,far)


const Layout = () => {
	const { currentPath, setCurrentPath, auth } = useContext(AuthContext);
	const [showMenu, setShowMenu ] = useState(false);

	const handleBurgerToggle = async (evt) => {
		setShowMenu(!showMenu);
		  //setShowMenu(showMenu=> { return !showMenu });
	}

	const hideMenu = async (evt) => {
		setShowMenu(false);
		  //setShowMenu(showMenu=> { return !showMenu });
	}
  return (
	<>
    <div id="wrap" className={(auth === true ? "authed" : null)}>
    <div className={ showMenu === true ? "left-bar toggled" : "left-bar"}>
		<div className="logo"><Link to="/">autom8</Link></div>
		<div className="menu" onClick={hideMenu}>
			<nav>
                <Menu />
			</nav>
		</div>
	</div>
	<div id="menu-toggle" className={(auth === true ? "" : "hidden")}>
		<div className="hamburger-toggle" onClick={handleBurgerToggle}> <FontAwesomeIcon icon="fa-solid fa-bars" /></div>
		<div className="logo"><Link to="/">autom8</Link></div></div>
    <div className="main" onClick={hideMenu}>
		{ auth === true ? (<Outlet />) : (<Login/>) }
    </div>
    </div>
	</>
  )
};

export default Layout;
