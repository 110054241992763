import axios from 'axios';
import jwtDecode from 'jwt-decode';

export const api = axios.create({
    baseURL: '/',
    headers: {
      'Authorization': ''
    }
  });

export function setAuthToken(token) {
    api.defaults.headers.common['Authorization'] = '';
    delete api.defaults.headers.common['Authorization'];

    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}
export function getDecodedToken() {
    const token = localStorage.getItem('token');
    if (!token) {
        // handle the error, for example, by redirecting to the login page
        return false;
    } else { 
        const decoded = jwtDecode(token);
        return decoded;
    }
}
export function checkTokenExpirationAndRefresh() {
    const token = localStorage.getItem('token');
    if (!token) {
        console.log("no token");
        return false;
    }
    try {
        const decoded = jwtDecode(token);
        //  console.log(decoded.exp * 1000, (Date.now() ) - 60 * 15);
        if ((Date.now() ) + 1000 * 60 * 15 > decoded.exp * 1000) {

            console.log("running refreshToken");
            let newToken = refreshToken();
            console.log(newToken);
            return newToken;
        }  else if(decoded.exp * 1000 < Date.now() ) { throw 'expired'; }
    } catch (error) {
        //return false;
    }
}

export const refreshToken = async () => {
    try {
        const currentToken = localStorage.getItem('token');
        api.defaults.headers.common['Authorization'] = currentToken;
        const { data } = await api.get('/api/refresh',{
            headers: {
            'Authorization': currentToken
            }
        });
        const newToken = data.token;
        localStorage.setItem('token', newToken);
        
        return newToken;
    } catch (error) {
        //throw error;
        return false;
        //console.log("yes",api.defaults.headers.common['Authorization']);
    }
  };
export const logout = async () => {
    api.defaults.headers.common['Authorization'] = ``;
    localStorage.removeItem('token');
};


export const login = async (email, password, rememberMe) => {
    try {
        const { data } = await api.post('/api/login', { email, password, rememberMe }).catch((e) => {throw "Något gick fel";});
        if(typeof data.token === "undefined" || data.token === "undefined") {
            throw data;
        }
        const token = data.token;
        setAuthToken(token);
        return token;
    } catch (error) {
        throw error;
    }
};

export function isLoggedIn() {
    const token = localStorage.getItem('token');
    if (!token) {
      // handle the error, for example, by redirecting to the login page
      return false;
    }
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        // handle the error, for example, by redirecting to the login page
        return false;
      } else {
        return true;
      }
    } catch (error) {
      // handle the error, for example, by redirecting to the login page
      return false;
    }
}


export const getServices = async (id=null) => {
    return new Promise( async (resolve, reject) => {
    const currentToken = localStorage.getItem('token');
    let endpoint = '/api/services';

    try { 
    const { data } = await api.get(endpoint,{
        headers: {
        'Authorization': 'Bearer ' + currentToken
        }
    });
        if(data.success === true && data.services) {
            return resolve(data);
        } else {
            throw data.message;
        }
    } catch(e) {
        console.log(e);
        
        return reject(false);
    }
    });
}
export const setInventoryThreshold = async (barcode, threshold, hostkey="1") => {
    return new Promise( async (resolve,reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/inventory/threshold/'+barcode+'/'+threshold+'/'+hostkey;

        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
        if(data.success)
        {
            resolve(barcode);
        }
        reject(false);
    
    })
};

export const setInventoryHostkeyTitle = async (hostkey,title) => {
    return new Promise( async (resolve,reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/inventory/hostkey/title';
        try { 
        const { data } = await api.post(endpoint,
            {
                hostkey: hostkey,
                title: title
            },
            {
            headers: {
            'Authorization': 'Bearer ' + currentToken
            },
            
        });
        if(data.success)
        {
            resolve(title);
        }
    } catch(e) {
        reject(false);
    }
        reject(false);
    
    })
};

export const getInventoryCSV = async (csv) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/inventory/'+csv;
    
        try { 
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            /*if(typeof data.success == "undefined") {
                return resolve(data);
            } else {
                throw data.message;
            }*/
            var blob = new Blob([data], { type: "text/csv" });
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);
            //document.location = downloadUrl;

            var a = document.createElement("a");
            a.href = downloadUrl;
            document.body.appendChild(a);
            a.style = "display: none";
            a.download = csv;
            a.click();

            console.log(data);

        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}

export const getInventoryHostKeys = async () => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/inventory_hosts';
    
        try {  
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}

export const getInventory = async (hostkey) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/inventory/'+hostkey;
    
        try {  
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true && data.csvs) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}

export const getCallbackConditions = async () => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/conditions';
    
        try { 
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true && data.conditions) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}

export const getCallbackGraphs = async () => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/conditions/graph';
    
        try { 
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}


export const getCallbackCondition = async (uuid) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/condition/'+uuid+'?t='+(new Date().getTime());
    
        try { 
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true && data.caller_condition) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}

export const saveCondition = async (conditionObject) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/condition/save';
    
        try { 
        const { data } = await api.post(endpoint,conditionObject, {
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}
export const editRule = async (id, action, object) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/condition/rule/edit/'+id;
        let ruleObject = object;
        ruleObject['action'] = action;
        try { 
        const { data } = await api.post(endpoint,ruleObject, {
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
    });
};
export const getTestEmail = async (id, testflags=false) => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/condition/get/test_email/'+id+'/'+(testflags === false ? "notest" : "testflags");
        try { 
            const { data } = await api.get(endpoint, {
                headers: {
                'Authorization': 'Bearer ' + currentToken
                }
            });
            if(data.success === true) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log("error" + e);
            return reject(false);
        }
    });
};


export const getCallLog = async() => {

}

export const addCallbackCondition = async () => {
    return new Promise( async (resolve, reject) => {
        const currentToken = localStorage.getItem('token');
        let endpoint = '/api/service/callback/condition/add';
    
        try { 
        const { data } = await api.get(endpoint,{
            headers: {
            'Authorization': 'Bearer ' + currentToken
            }
        });
            if(data.success === true && data.uuid) {
                return resolve(data);
            } else {
                throw data.message;
            }
        } catch(e) {
            console.log(e);
            return reject(false);
        }
        });
}
