import {createContext, useState, useEffect} from 'react';
// import { login, logout, isLoggedIn, checkTokenExpirationAndRefresh } from '../pages/Api';
import {  logout, isLoggedIn, checkTokenExpirationAndRefresh } from '../pages/Api';
const AuthContext = createContext({});
export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [currentPath, setCurrentPath] = useState("/");
    const [accountLevel, setAccountLevel] = useState({'services':[]});
    useEffect( () => {
        if(isLoggedIn() === false)
        {
            logout();
            setAuth(false);
        } else { 
            setAuth(true);
        }
        
    }, []);
    useEffect( () => {
        const intervalId = setInterval(() => {
          try {
            let checkToken = checkTokenExpirationAndRefresh();
            if(checkToken === false){
                console.log(checkToken);
                throw 'expired'
            }
          } catch(e) {
            //console.log("setauth: false", e);
            logout();
            setAuth(false);
        }
        }, 5000); 
    
        return () => clearInterval(intervalId);
      }, []);




    return (
        <AuthContext.Provider value={{auth,setAuth,accountLevel,setAccountLevel,currentPath,setCurrentPath}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;