import React, {useEffect, useState, useRef} from 'react'
//import { getGraphs } from './Api';
import { getInventory, getInventoryCSV,setInventoryThreshold, getInventoryHostKeys, setInventoryHostkeyTitle } from './Api';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";

const Inventory = () => {
    const [csvs, setCSVs] = useState([]);
    const [hostkeys, setHostkeys] = useState([]);
    const [permission, setPermission] = useState(0);
    const [waitUpdate, setWaitUpdate] = useState(null);
    const [inventoryAssets, setInventoryAssets] = useState([]);
    const [editField, setEditField] = useState(null);

    useEffect( () => { 
        setInventoryAssets([]);
        setHostkeys([]);
        getInventoryHostKeys().then(async function(data) {
            setHostkeys(data.inventory_hosts);

            for(let i in data.inventory_hosts){
                let hostkey = data.inventory_hosts[i];
                await getInventory(hostkey.permission_key).then(function(inventoryData) {
                    setPermission(inventoryData.permission);
                    setInventoryAssets( (prevInventoryAssets) => { return [...prevInventoryAssets, {hostkey: hostkey.permission_key, title: data.inventory_hosts[i].title, inventory: inventoryData.inventory_assets } ] } );
                    //setInventoryAssets(data.inventory_assets);
                    
                });
            }
        });

        /*getInventory().then(function(data) {
          
          //setCSVs(data.csvs);
          //setInventoryAssets(data.inventory_assets)
          //setPermission(data.permission);
        });
        */
    
      }, []);

    const handleDownload = (evt) => {
        console.log(evt.nativeEvent.target );
        getInventoryCSV(evt.target.dataset.csv);
        
    }

    const handleUpdateThreshold = async(evt) => {
        console.log(evt.target.dataset.barcode, "=>", evt.target.value);
        // Don't save immediately!
        try{ clearTimeout(waitUpdate);}catch(e) {console.log(e); }
        evt.target.parentElement.querySelector(".save-inventory").style.display='block';
        
        setWaitUpdate(
            setTimeout(()=>{
                setInventoryThreshold(evt.target.dataset.barcode, evt.target.value, evt.target.dataset.hostkey).then( () => {evt.target.parentElement.querySelector(".save-inventory").style.display='none';});
            }, 300)    
        );
        //evt.target.parentElement.querySelector("button").classList.add("active");
    };

    const handleEditName = async(evt) => {
        console.log(evt.target);
        setEditField(evt.target.dataset.hostkey);
    };

    const handleSaveNameField = async(evt) => {
        console.log(evt.target);

        console.log(evt.target.parentElement.querySelector(".save-inventory"));
        let parent = evt.target.parentElement;
        let hostkey = evt.target.dataset.hostkey;
        let save_inventory_spinner = evt.target.parentElement.querySelector(".save-inventory");
        evt.target.parentElement.querySelector(".save-inventory").style.display='inline-block';
        let value = ""+(evt.target.parentElement.querySelector(".edit-box").value);
        setInventoryHostkeyTitle(hostkey, value).then( (data) => {
            save_inventory_spinner.style.display='none';
            //parent.querySelector(".hostkeytitle").textContent = value;
            
            // replace the hostkey title in the inventoryAssets array
            let newInventoryAssets = [...inventoryAssets];
            for(let i in newInventoryAssets){
                if(newInventoryAssets[i].hostkey === hostkey){
                    newInventoryAssets[i].title = value;
                }
            }
            setInventoryAssets(newInventoryAssets);
            setEditField(null);
            
        }).catch( (e) => {
            setEditField(null);
            save_inventory_spinner.style.display='none';
        });
    }

    return (<>
        <div className="top-bar">
        Service: Inventory
        </div>

        
        <div className="service">

            <div className="section inventory">
            <div className="title">Inventory</div>
            <div className="information">The Inventory Assets table below is updated once every hour if the Raspberry PI has connection to the internet.
            <br/>Admins can set threshold, if the amount goes below the given threshold, an alert mail will be sent to your specified contact at 12:45.</div>
            <br/>
            <hr/>
            <br/>
            <div className=""><div>
            {/* <div className="information">Last received update: { inventoryAssets.length > 0 ? (new Date(inventoryAssets[0].last_update_time*1000).toLocaleString("sv-SE")) : "" }</div> */}
            <br/>
            <div className="inventory-flex">
                {inventoryAssets.length > 0 ? (<></>) : (<> <span className="save-inventory relative show gray"><FontAwesomeIcon icon="fa-solid fa-spinner" /></span></>)}
            { inventoryAssets.map( (hostkey, index) => {
            return (<div className="inventory-asset-box"  key={"hostkey_asset_box_"+index} >
            <div className="inventory-title" key={"hostkey_title_"+index}>
                { editField === hostkey.hostkey ? (<><input type='text' className='edit-box' defaultValue={hostkey.title} />
                <a className="inventory-comment edit-key green" data-hostkey={hostkey.hostkey} onClick={handleSaveNameField}><FontAwesomeIcon icon="fa-solid fa-floppy-disk" /></a></>) : (<><span className="hostkeytitle">{hostkey.title}</span> { permission === 3 ? (
                <a className="inventory-comment edit-key" onClick={handleEditName} data-hostkey={hostkey.hostkey}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></a>
                ) : (<></>) }</>)} <div className="save-inventory relative"><FontAwesomeIcon icon="fa-solid fa-spinner" /></div>
                <div className="inventory-comment" key={"hostkey_comment_"+index}>ID: {hostkey.hostkey}</div>
                <div className="inventory-comment small italic">Last update: {new Date(hostkey.inventory[0].last_update_time*1000).toLocaleString("sv-SE")}</div>
                </div>

            <table className="inventory" key={"hostkey_table_"+index}>
                <tbody>
                <tr>
                <td>Asset</td><td>Amount</td><td>Alert Threshold</td><td></td>
                </tr>
                { typeof hostkey.inventory.length != "undefined" ? ( hostkey.inventory.map( (inventoryAsset,index) => {return (
                <tr key={"inventoryAsset_id_"+index} ><td>{inventoryAsset.title}</td><td className={ inventoryAsset.amount < inventoryAsset.threshold ? "warn" : "" }>{inventoryAsset.amount}</td><td className="threshold-td"> { permission === 3 ? ( <><input className="threshold" onChange={handleUpdateThreshold} data-barcode={inventoryAsset.barcode} data-hostkey={inventoryAsset.hostkey} type="number" defaultValue={inventoryAsset.threshold} /> <div className="save-inventory"><FontAwesomeIcon icon="fa-solid fa-spinner" /></div></> ) : (<>{inventoryAsset.threshold}</>) } </td><td className="warn">{ inventoryAsset.amount < inventoryAsset.threshold ? (<><FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /></>) : (<></>) }</td></tr>
                )}) ) : (<>Loading...</>) }
                </tbody>
            </table>

            </div>)
            } ) }


            
            </div>
            
            </div></div>
            </div>
        </div>
        </>);
}
export default Inventory
