import React, {useEffect, useState, useRef} from 'react'
//import { getGraphs } from './Api';
import { getCallbackConditions, addCallbackCondition,getCallbackGraphs } from './Api';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";

import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";


function getDateFromDaysAgo(numDays) {
  const today = new Date();
  const pastDate = new Date();
  pastDate.setDate(today.getDate() - numDays);

  const year = pastDate.getFullYear();
  const month = String(pastDate.getMonth() + 1).padStart(2, '0');
  const day = String(pastDate.getDate()).padStart(2, '0');

  const formattedDate = `${month}/${day}`;
  return formattedDate;
}
const CallLog = ({logs}) =>{
  const paginationNum = 8;
  const [fullLogs, setFullLogs] = useState(logs);
  const [logPage, setLogPage] = useState(0);
  const [currLogs, setCurrLogs] = useState(logs.slice(logPage,logPage+paginationNum));
  const handleNext = async (evt) => {
    if(parseInt((logPage+paginationNum)/paginationNum)<Math.ceil(fullLogs.length / paginationNum)){
      setLogPage(prevlogPage => { return logPage+paginationNum});
    }
  }
  const handlePrevious = async (evt) => {
    //evt.
    if(logPage > 0){
      setLogPage(prevlogPage => { return logPage-paginationNum});
    }
    //setCurrLogs(() => {return fullLogs.slice(logPage,logPage+10)});
  }

  useEffect(() => {
    if(fullLogs.length > logPage && logPage > -1){
      setCurrLogs(fullLogs.slice(logPage, logPage+paginationNum));
    }
  }, [logPage, fullLogs]);

  useEffect(() => {
      setCurrLogs(fullLogs.slice(logPage, logPage+paginationNum));
  }, []);

  return (<><div className="callLog">
  <table>
    <thead>
      <th>Condition</th>
      <th>To phone</th>
      <th>From phone</th>
      <th>Time</th>
    </thead>
    <tbody>
      {currLogs.map( (log, index)  => {
      return (<tr key={"tr__index_"+index}>
        <td>{log.description}</td>
        <td>{JSON.parse(log.action_data).phone}</td>
        <td>{(log.from)}</td>
        <td>{(new Date(log.time_epoch*1000).toLocaleString("sv-SE", { timeZoneName: 'short' }))}</td>
      </tr>)})
      }
    </tbody>
  </table>
  </div>
  <div class="pagination-navigation">
  {(<button onClick={handlePrevious} disabled={logPage < 1}>Previous</button>)}  <div>{parseInt((logPage+paginationNum)/paginationNum)}/{Math.ceil(fullLogs.length / paginationNum)}</div>  {  (<button onClick={handleNext} disabled={parseInt((logPage+paginationNum)/paginationNum)>=Math.ceil(fullLogs.length / paginationNum)}>Next</button>) } 
  </div>
  </>);
};
const CallChart = ({graphData}) =>{
  // let colors = ["#99cff4","#99f4ab", "#f49d99", "#99f4ab", "#d8db8b"]; // "backgroundColor":colors[index], 
  let datasets = [];
  datasets = Object.keys(graphData).map( (key,index) => { return  {"label": key, "data": graphData[key].map( (obj)=>{ return obj.count} ) } })
  console.log("datasets:", datasets);
  const config = {
    type: 'bar',
    data: {
      labels: graphData[Object.keys(graphData)[0]].map( (obj) => { return getDateFromDaysAgo(obj.day) }),
      datasets:  datasets,
    },
    options: {
      maintainAspectRatio: false,
      responsive:true,
      plugins: {
        title: {
          display: false,
          text: 'Stacked Bar Chart'
        },
      },
      scales: {
        x: {
          stacked: true,
          reverse: true
        },
        y: {
          stacked: true
        }
      }
    }
  };
  console.log(config);

    return (
      <div className="chart-container">
        <Bar options={config['options']} data={config['data']} />
      </div>
    );
}

/*import Chart from 'chart.js';*/


library.add(fas,far)

const Condition = ({id, enabled=null, description, phone, used, lastTrigger}) => {
  const url = `/services/callback/edit/${id}`;
  return (
    <>
    <Link className="select-condition" to={url}>
        <div className="description"><div className="toggle">{enabled === 1 ? (<><FontAwesomeIcon className="toggledOn" icon="fa-solid fa-toggle-on" /></>) : (<><FontAwesomeIcon className="toggledOff" icon="fa-solid fa-toggle-off" /></>)}
        </div>
        <div className="wrap">{description}</div></div>
        <div className="phone"><FontAwesomeIcon icon="fa-solid fa-phone-volume"/> {phone}</div>
          <div className="used"><FontAwesomeIcon icon="fa-solid fa-timeline" /> {used} times</div>
          <div className="lastTrigger"><FontAwesomeIcon icon="fa-solid fa-clock" /> {lastTrigger}</div>
    </Link>
    </>
    )
}


const Caller = () => {
  
  let navigate = useNavigate();
  const addCondition = async () => {
    // Call API to create new
    if(addingCondition === false){
      setAddingCondition(true);
      let d = addCallbackCondition().then( (callbackCondition) => {
        getCallbackConditions().then(function(data) {
          console.log(data.conditions);
          setConditions(data.conditions);
          setAddingCondition(false);
          navigate(`/services/callback/edit/${callbackCondition.uuid}`);
        });
      }).catch( (e) => {
        console.log(e);
        alert("Something went wrong...");
        setAddingCondition(false);
      });
      console.log(d)
    }
  };
  const [conditions, setConditions] = useState(false);
  const [callbackGraph, setCallbackGraph] = useState([]);
  const [callbackLog, setCallbackLog] = useState([]);
  const [addingCondition, setAddingCondition] = useState(false);
  useEffect( () => {
    getCallbackConditions().then(function(data) {
      console.log(data.conditions);
      setConditions(data.conditions);
    });

    getCallbackGraphs().then(function(data) {
      setCallbackLog(data.call_data);
      setCallbackGraph(data.graph_data);

    });
  }, []);


  return (
    <>
    <div className="top-bar">
    Service: Callback
    </div>
    <div className="service">
      <div className="graphs flex">
        <div className="section ">
          <div className="title">Graph</div>
          <div>
           { Object.keys(callbackGraph).length > 0 ? 
              (<CallChart graphData={callbackGraph} />) 
              : ("No calls matching")
           }
          </div>
        </div>
        <div className="section ">
          <div className="title">Call log</div>
          <div>
           { callbackLog.length > 0 ?
              (<CallLog logs={callbackLog} />) 
              : ("No calls matching") 
           }
          </div>
        </div>
      </div>
      <hr className="divider"/>
      <div className="section conditions">
        <div className="title">Conditions</div>
        Add or edit your conditions for calling below. <br/>
        <button onClick={addCondition} className={ addingCondition ? ( "addConditionButton active" ) : ( "addConditionButton" )  }> { addingCondition  === false ? (<FontAwesomeIcon icon="fa-regular fa-square-plus" />) : (<FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" />) } Add Condition</button>
        <div>
        <div className="describe-condition">
        <div className="description"><div className="toggle"><FontAwesomeIcon icon="fa-solid fa-toggle-on" /></div>
        <div className="wrap">Description</div>
        </div>
        <div className="phone">Phone number</div>
          <div className="used">Triggered times</div>
          <div className="lastTrigger">Last Trigger</div>
    </div>
        { typeof conditions.length != "undefined" ? ( conditions.map( (cond,index) => {return (
          <Condition key={"condition_id_"+index} enabled={cond.enabled} description={cond.description} phone={JSON.parse(cond.action_data).phone}  used={cond.triggered_times} id={cond.condition_uuid} lastTrigger={cond.last_trigger > 0 ? (new Date(cond.last_trigger*1000).toLocaleString("sv-SE", { timeZoneName: 'short' })) : ("n/a")}/>
          )}) ) : (<>Loading...</>) }
        
        </div>
      </div>
    </div>
    </>
  )
}
/*
<Condition description="Alert Condition 123213121" used="37" phone="+46739834491" lastTrigger="2023-03-14 12:01 +1 GMT"/>
<Condition description="Alert Condition 1" used="37" phone="+46739834491" lastTrigger="2023-03-14 12:01 +1 GMT"/>
*/
export default Caller