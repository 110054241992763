import React, {createContext,useEffect, useState, useRef, useId} from 'react'
import { useParams } from 'react-router';
//import { getGraphs } from './Api';
import { getCallbackConditions, getCallbackCondition,saveCondition, editRule, getTestEmail } from './Api';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";
library.add(fas,far)

const Condition = ({id, enabled=null, description, phone, used, lastTrigger}) => {
  const url = `/services/callback/edit/${id}`;
  return (
    <>
    <Link className="select-condition" to={url}>
        <div className="description">{enabled === 1 ? (<><FontAwesomeIcon className="toggledOn" icon="fa-solid fa-toggle-on" /></>) : (<><FontAwesomeIcon className="toggledOff" icon="fa-solid fa-toggle-off" /></>)} {description}</div>
        <div className="phone"><FontAwesomeIcon icon="fa-solid fa-phone-volume"/> {phone}</div>
          <div className="used"><FontAwesomeIcon icon="fa-solid fa-timeline" /> {used} times</div>
          <div className="lastTrigger"><FontAwesomeIcon icon="fa-solid fa-clock" /> {lastTrigger}</div>
    </Link>
    </>
    )
}


const ConditionEdit = () => {
  let navigate = useNavigate();
  const { uuid } = useParams();
  

  const audioRef = useRef(null);
  const [callerCondition, setCallerCondition] = useState(null);
  const [error, setError] = useState(null);
  const [savestatus, setSavestatus] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [ruleset, setRuleset] = useState([]);
  const [description, setDescription] = useState(null);
  const [sound, setSound] = useState(null);
  const [soundsrc, setSoundsrc] = useState(null);
  const [phonenumber, setPhonenumber] = useState(null);
  const [testEmail, setTestEmail] = useState(null);
  const [testRunning, setTestRunning] = useState(false);
  const toggleEnabled = () => {
    setIsEnabled(! ( isEnabled ) );
  }

  const handleSwapSound = (e) => {
    let val = e.target.value;
    setSound(val);
    let play_sounds = [
        'alert-checkyouremail.mp3', 
        'thisisatest-noactionisreququired.mp3', 
        'alert-highpriority-male.mp3',
        'alert-mediumpriority-male.mp3',
        'alert-lowpriority-male.mp3',
        'alert-highpriority-female.mp3',
        'alert-mediumpriority-female.mp3',
        'alert-lowpriority-female.mp3'
    ]; 
    const audio = audioRef.current;
    setSoundsrc(play_sounds[parseInt(val)-1]);
  } 

  const handlePlay = (e) => {
    e.preventDefault();
    const audio = audioRef.current;
    console.log(soundsrc);
    audio.play();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSavestatus('Saving...')
    try {
        
        let conditionObject = {
            "enabled": isEnabled,
            "description": description,
            "phonenumber": phonenumber,
            "sound": sound,
            "uuid": uuid
        };
        console.log(e);
        saveCondition(conditionObject).then( (data) => {
            console.log(data);
            setSavestatus(null);

            //setAccountLevel({"services":data.services});
        }).catch( (e) => {
            console.log(e);
            setError('Something went wrong');
            setSavestatus(null);
        });
    } catch (err) {
        //setError(err);
        setSavestatus(null);

        console.log(err);
    }
};

  console.log(uuid);

  const handleTest = async  () =>{
    //setTestEmail(true);
    setTestRunning(true);
    getTestEmail(uuid, true).then( (data) => {
        console.log(data);
        //setTestEmail(data.);
        setTestEmail(prevEmail => {
            return data.last_test_mail;
        });
        setRuleset(prevRuleset => {
            return [...data.ruleset_w];
        });
        setTestRunning(false);
    }).catch ( (e) => {
        console.log(e);
        setTestRunning(false);
    });
  }


  useEffect( () => {
    const intervalId = setInterval(() => {
        getTestEmail(uuid).then( (data) => {
            console.log(data);
            setTestEmail(prevEmail => {
                return data.last_test_mail;
            });
        }).catch ( (e) => {
            console.log(e);
        });
      }, 1000*20);
      //

    getTestEmail(uuid).then( (data) => {
        setTestEmail(prevEmail => {
            return data.last_test_mail;
        })
    }).catch ( (e) => {
        console.log(e);
    });
    getCallbackCondition(uuid).then(function(data) {
        console.log(data);
        setCallerCondition(data.caller_condition);
        setIsEnabled(data.caller_condition.enabled === 1);
        setDescription(data.caller_condition.description);
        setPhonenumber(JSON.parse(data.caller_condition.action_data).phone);
        setRuleset(data.ruleset);
        let play_sounds = [
            'alert-checkyouremail.mp3', 
            'thisisatest-noactionisreququired.mp3', 
            'alert-highpriority-male.mp3',
            'alert-mediumpriority-male.mp3',
            'alert-lowpriority-male.mp3',
            'alert-highpriority-female.mp3',
            'alert-mediumpriority-female.mp3',
            'alert-lowpriority-female.mp3'
        ];
        for(let i in play_sounds){
            //console.log(play_sounds[i], JSON.parse(data.caller_condition.action_data).sound, JSON.parse(data.caller_condition.action_data));
            if(play_sounds[i] === JSON.parse(data.caller_condition.action_data).sound){
                setSound(parseInt(i)+1);
                setSoundsrc(play_sounds[parseInt(i)]);
                break;
            }
        }
        
        
    });
    return () => clearInterval(intervalId);
  }, []);


  return (
    <>
    <div className="top-bar">
        <Link to="/services/callback" className="backButton" > <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> Go back </Link>
        Callback - Editing Condition: {callerCondition!=null ? (callerCondition.description) : ("Loading...")}
    </div>
    <div className="service caller">
        <div className="titlebar">Edit Callback settings</div>
        { callerCondition ? (<>
        <form onSubmit={handleSubmit}>
        <input type="hidden" name="uuid" value={uuid} />
        <div className="text-entry">
            <label>
                Description<br/>
                <input type="text" name="description" className="textbox" defaultValue={description} onChange={e => setDescription(e.target.value)}/>
            </label>
        </div>
        <div className="text-entry">
            <label>
                Call Phone number (include country code, i.e. +46701234567)<br/>
                <input type="text" name="phone" className="textbox" defaultValue={phonenumber} placeholder="+4673000000" onChange={e => setPhonenumber(e.target.value)}/>
            </label>
            <div className="descriptive-text">The phone number that will be called when the specified conditions are met.</div>
        </div>

        <div className="text-entry">
            <label>
                Sound<br/>
                <select name="sounds" onChange={handleSwapSound} defaultValue={sound}>
                    <option value="1">Alert: Check your email</option>
                    <option value="2">This is a test. No action is required.</option>
                    <option value="3">(Male) Alert: High Priority </option>
                    <option value="4">(Male) Alert: Medium Priority </option>
                    <option value="5">(Male) Alert: Low Priority </option>
                    <option value="6">(Female) Alert: High Priority </option>
                    <option value="7">(Female) Alert: Medium Priority </option>
                    <option value="8">(Female) Alert: Low Priority </option>
                </select>
            </label> 
            &nbsp;<button className="caller_play" onClick={handlePlay}><FontAwesomeIcon icon="fa-solid fa-circle-play" /></button><audio ref={audioRef} src={"/short_"+soundsrc} />

            <div className="descriptive-text">Sound to play when phone is answered</div>
        </div>
        <div className="text-entry">
            <label className="isEnabled">
                Enable/Disable <br/>
                <div onClick={toggleEnabled} className={ isEnabled === false ? ( "" ) : ( "active" )  }> { isEnabled  === false ? (<FontAwesomeIcon icon="fa-solid fa-toggle-off" />) : (<FontAwesomeIcon icon="fa-solid fa-toggle-on" />) } 
                 </div>
            </label>
            <div className="descriptive-text">Currently saved setting is: {callerCondition.enabled ? "Enabled" : "Disabled" }</div>
        </div>

        <div className="save-div">
            <button onClick={handleSubmit} className={ savestatus === null ? ( "submit-save" ) : ( "submit-save active" )  }> { savestatus  === null ? ("Save settings") : (<FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" />) }< /button>
            <div className="error">{error}</div>
        </div>

        <hr/>
        </form>

        <div className="titlebar">Live Edit Rules
        <br/>
        Note: All rule changes are applied immediately.
        </div>
        
        <div className="caller-flex">
            <div className="left">
                {<Ruleset uuid={uuid} ruleset={ruleset} setRuleset={setRuleset} />}
            </div>
            <div className="right">
                To live test these rules as you build them, you can send a sample email to the following address: <br/>
                <div className="copy-email">test_{uuid.slice(0,8)}@autom8.se</div><br/><br/>

                <div className="descriptive">
                 - Rule "mail_to" will be ignored in tests<br/>
                 - Server time is CET/CEST <br/>
                 <br/>
                 <br/>

                </div>
                { (ruleset[0]['trueFlag'] || ruleset[0]['falseFlag'] )  && ( ruleset[0]['trueFlag'] === true ? (<>
                    <div className="test-result success">Success: These rules would generate a phone call based on the received email.</div>
                </>) : (<>
                    <div className="test-result fail">Fail: These rules would not generate a phone call.</div>
                </>) ) || <div className="test-result">&nbsp;</div>}
                <button onClick={handleTest} className={ (testRunning === true) ? ("test-button active") : "test-button" }>{(testRunning === true) && (<FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" />) } Test rules on the received test email</button>
                <br/><br/>
                Last received test email:
                <div className="descriptive">
                
                <div className="received-email">
                    {(testEmail !== null) ? (<>
                    <div className="entry from"><div>From: </div><div>{testEmail.mail_from}</div></div>
                    <div className="entry to"><div>To: </div><div>{testEmail.mail_to}</div></div>
                    <div className="entry time"><div>Time received: </div><div>{testEmail.time}</div> </div>
                    <div className="entry subject"><div>Subject: </div><div>{testEmail.subject}</div></div>
                    <div className="entry body"><div>Body:</div>
                    <div>
                    {testEmail.body}</div></div>
                    </>) : (<>No test mail received.</>) }
                </div>
                </div>

                
            </div>
        </div>



        </>) : "" }

    </div>
    </>
 )
}

//setRuleset([]);//{"id":1,"account_id":1,"type":"main","object":null,"statement":null,"sublogic":"and","parent_condition_id":null,"subconditions":[{"id":2,"account_id":1,"type":"body","object":"jonasj@interxion.com","statement":"contains","sublogic":"or","parent_condition_id":1,"subconditions":[{"id":5,"account_id":1,"type":"mail_to","object":"anton@lol.com","statement":"contains","sublogic":"and","parent_condition_id":2,"subconditions":[]}]},{"id":3,"account_id":1,"type":"time","object":"2022","statement":"before","sublogic":"and","parent_condition_id":1,"subconditions":[{"id":4,"account_id":1,"type":"body","object":"2020","statement":"after","sublogic":"and","parent_condition_id":3,"subconditions":[]}]}]}]);


const Ruleset = ({uuid, ruleset, setRuleset}) => {
    const [nest, setNest] = useState(0);
    const [initialRule, setInitialRule] = useState(JSON.stringify(ruleset[0]));
    const refresherFunction = () => {
        return new Promise( (resolve,reject) => {
        console.log(uuid);
        getCallbackCondition(uuid).then(function(data) {
            console.log(data.ruleset);
            /*setRuleset([]);
            setTimeout( () => setRuleset(data.ruleset), 1);*/
            setRuleset(prevRuleset => {
                return [...data.ruleset];
              });
            resolve(true);
            }).catch((e) =>{ resolve(false) });  
        })
    }
    useEffect(() => {
        //alert("changed");        
    }, [ruleset])

    return (<>
        <div>
        {ruleset.length > 0 && (
        <Rule rule={ruleset[ruleset.length-1]} nest={0} refresherFunction={refresherFunction}/>
        )}
        </div>
    </>)
};

const RuleType = ({rule, refresherFunction}) => {
    const [loading, setLoading] = useState(false);
    function handleChange(evt){
        let action = "edit";
        setLoading(true);
        setTimeout( () => {setLoading(false)}, 5000);
        editRule(rule.uuid, action, {"type":evt.target.value}).then( (data) => {
            console.log(data);
            refresherFunction().finally( () => { setLoading(false); });
        });;
    }
    let possibleTypes = [  'time', 'mail_from', 'mail_to', 'subject', 'body', 'weekday', 'retrigger'];
    let type = rule.type;
    let currentType = (<option defaultValue={type}>{type}</option>)
    let allowedTypes = possibleTypes.filter( (a) => { return a !== type+"aa" } );
    
    if(rule.type === "main" ||rule.type === "group") {
      return "";
    }
    // <option value={statement}>{statement}</option>
    return (<><select onChange={handleChange} value={type}>{allowedTypes.map( (possibleType, index) => { return (<option value={possibleType}  key={"opt__"+index} >{possibleType}</option>)}) }</select>
    {loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}
    </>)
}


const RuleStatement = ({rule, refresherFunction}) => {
    const [loading, setLoading] = useState(false);
    function handleChange(evt){
        let action = "edit";
        setLoading(true);
        setTimeout( () => {setLoading(false)}, 5000);
        editRule(rule.uuid, action, {"statement":evt.target.value}).then( (data) => {
            console.log(data);
            refresherFunction().finally( () => { setLoading(false); });
        });;
        
    }
    let statement = rule.statement;
    let type = rule.type;
  
    let possibleStatements = ['contains','equals'];
    if(type === "mail_from" || type === "mail_to") {
      possibleStatements = ['contains','equals'];
    }
    if(type === "time") {
      possibleStatements = ['before','after'];
    }
    if(type === "weekday") {
        possibleStatements = ['on or before','on or after','equals'];
    }
    if(type === "retrigger") {
        possibleStatements = ['not within','within'];
    }
    if(type === "main") {
      possibleStatements = [];
    }
    if(type === "true") {
      possibleStatements = [];
    }
    
    
    let currentStatement = possibleStatements.indexOf(statement) !== -1 ? (<option value={statement}>{statement}</option> ) : "";
    let allowedStatements = possibleStatements.filter( (a) => { return a !== statement } );
    // <option value={statement}>{statement}</option>
    if(possibleStatements.length !== 0){
      return (<><select onChange={handleChange} value={statement}>{currentStatement}{allowedStatements.map( (possibleStatement, index) => { return (<option value={possibleStatement} key={"statement_opt_"+"_"+index}>{possibleStatement}</option>)}) }</select>
      {loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}</>)
    } else { 
      return null;
    }
  }
const RuleObject = ({rule, refresherFunction}) => {
    const [conditionList, setConditionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const minute_retrigger = useRef(null);
    const condition_retrigger = useRef(null);
    function handleChange(evt){
        let action = "edit";
        setLoading(true);
        setTimeout( () => {setLoading(false)}, 5000);
        editRule(rule.uuid, action, {"object":evt.target.value}).then( (data) => {
            console.log(data);
            refresherFunction().finally( () => { setLoading(false); });
        });
    }

    function handleChange_retrigger(evt) {
        //console.log(evt);
        console.log(condition_retrigger.current.value);
        console.log(minute_retrigger.current.value);

        let action = "edit";
        setLoading(true);
        setTimeout( () => {setLoading(false)}, 5000);
        editRule(rule.uuid, action, {"object":JSON.stringify({"condition_uuid":condition_retrigger.current.value, "minutes":minute_retrigger.current.value }) }).then( (data) => {
            refresherFunction().finally( () => { setLoading(false); });
        });
                
    }
  let object = rule.object;
  console.log(object);
  if(rule.type === "mail_to") { return (<><div><input type="text" className="email-entry" onBlur={handleChange} defaultValue={object} /> @autom8.se </div>{loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}</>); }
  if(rule.type === "retrigger") {
    if(conditionList.length === 0){ 
        getCallbackConditions().then((data) => {   
            if(data.conditions){
                setConditionList(data.conditions);
            }
        }).catch(()=>{ return []; });
    }
    let defaultMinutes = "15";
    let defaultCondition = "";
    try{
        defaultMinutes = JSON.parse(object).minutes;
        defaultCondition = JSON.parse(object).condition_uuid;
    } catch(e){
    }
    console.log(defaultMinutes);
    console.log(defaultCondition);
    return( <>
        <div>
        <select onChange={handleChange_retrigger} className="small-width" ref={minute_retrigger} value={defaultMinutes}>
            <option value="5">5 minutes</option>
            <option value="10">10 minutes</option>
            <option value="15">15 minutes</option>
            <option value="30">30 minutes</option>
            <option value="60">60 minutes</option>
        </select>
        <br/>
        of triggering:<br/>
        <select onChange={handleChange_retrigger} ref={condition_retrigger} value={defaultCondition}>
            {conditionList.map( (condition,index) => {
                return (<option value={condition.condition_uuid} key={"condition__dropdown_"+index} >{condition.description}</option>);
            })}
        </select>
        </div>
    </>);
  }
  if(rule.type === "time") {
    return (<>
        <select onChange={handleChange} value={object}>
            <option value="00:00">00:00</option>
            <option value="01:00">01:00</option>
            <option value="02:00">02:00</option>
            <option value="03:00">03:00</option>
            <option value="04:00">04:00</option>
            <option value="05:00">05:00</option>
            <option value="06:00">06:00</option>
            <option value="07:00">07:00</option>
            <option value="08:00">08:00</option>
            <option value="09:00">09:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
            <option value="18:00">18:00</option>
            <option value="19:00">19:00</option>
            <option value="20:00">20:00</option>
            <option value="21:00">21:00</option>
            <option value="22:00">22:00</option>
            <option value="23:00">23:00</option>
            <option value="23:59">23:59</option>
        </select>
        {loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}
        </>); 
    }
    // return (<div><input type="text" onBlur={handleChange} defaultValue={object} placeholder="example: 23:00" /></div>); }
  if(rule.type === "main") { return ""; }
  if(rule.type === "weekday") { return (<>
    <select onChange={handleChange} value={object} >
        <option value="1">Monday</option>
        <option value="2">Tuesday</option>
        <option value="3">Wednesday</option>
        <option value="4">Thursday</option>
        <option value="5">Friday</option>
        <option value="6">Saturday</option>
        <option value="7">Sunday</option>
    </select>
    {loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}
    </>); }
  if(rule.type === "group") { return ""; }
  return  <><input type="text" onBlur={handleChange} defaultValue={object} /> 
  {loading && ( <div className="loading-overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" className="spinner" /></div>)}</>;

}

const Rule = ({rule,refresherFunction,nest}) => {
    
    let ruleClass = (rule['type'] === "group" || rule['type'] === "main") ? "rule group" : "rule";
    let rulesetClass = (rule['type'] === "group" || rule['type'] === "main") ? "ruleset-wrapper group" : "ruleset-wrapper";
    function handleLogic(evt){
        let action = "edit";
        editRule(rule.uuid, action, {"sublogic":evt.target.value}).then( (data) => {
            console.log(data);
            refresherFunction();
        });
    }

    const handleDelete = () => {
        console.log(rule['uuid']);
        let action = "delete";
        editRule(rule.uuid, action, {}).then( (data) => {
            console.log(data);
            refresherFunction();
        });;
    }

    const addRule = () => {
        console.log(rule['uuid']);
        let action = "addrule";
        editRule(rule.uuid, action, {}).then( (data) => {
            console.log(data);
            refresherFunction();
        });;
    }
    const addGroup = () => {
        console.log(rule['uuid']);
        let action = "addgroup";
        editRule(rule.uuid, action, {}).then( (data) => {
            console.log(data);
            refresherFunction();
        });;
    }

/*    <button> <FontAwesomeIcon icon="fa-solid fa-plus" /> Rule </button>
    <button> <FontAwesomeIcon icon="fa-solid fa-trash-can" /> </button>
    
    {nest === 0 ? ("If") : ("And if")}
    */
    return (
    <div className={rulesetClass}>
    { (rule['type'] !== "group"  && rule['type'] !== "main") || rule['subconditions'].length != 0 && (
    <div className="type">
    If <select defaultValue={rule['sublogic']} onChange={handleLogic}>
        <option value="and">All</option>
        <option value="or">Any</option>
        </select> of the following conditions are met
    </div>)}
    
    <div className={ruleClass}>
    { (rule['type'] !== "group"  && rule['type'] !== "main")  && (
    <>
    <div className="ruleOptions">
        <div className="small-width"><RuleType rule={rule} refresherFunction={refresherFunction} /></div>
        <div className="small-width"><RuleStatement rule={rule} refresherFunction={refresherFunction} /></div>
        <RuleObject className="large-width" rule={rule} refresherFunction={refresherFunction} />
        <button className="delete" onClick={handleDelete}><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /></button>
    </div>
        
    </>
    )}
        { (rule['type'] !== "group"  && rule['type'] !== "main") || rule['subconditions'].length != 0 &&  ( rule['subconditions'].map( (sub,index) => { return <Rule rule={sub} nest={nest+1} key={"rule_index_"+index} refresherFunction={refresherFunction} />
        }))
        }

    { (rule['type'] === "group" || rule['type'] === "main")  && 
    (<div className="groupButtons">
    <button onClick={addRule} ><FontAwesomeIcon icon="fa-solid fa-plus" /> Add rule</button>
    <button onClick={addGroup} > <FontAwesomeIcon icon="fa-solid fa-layer-group" /> Add rule group</button>
    { (rule['subconditions'].length === 0 && rule['type'] === "group") && (<button onClick={handleDelete}><FontAwesomeIcon icon="fa-solid fa-layer-group" /> Delete group</button>) }
    </div>)
    }
        
        {rule['falseFlag'] === true && (<div className="falseflag checkflag"><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>)}
        {rule['trueFlag'] === true && (<div className="trueflag checkflag"><FontAwesomeIcon icon="fa-solid fa-check" /></div>)}
        
    </div>
    </div>
    )

}

/*
<Condition description="Alert Condition 123213121" used="37" phone="+46739834491" lastTrigger="2023-03-14 12:01 +1 GMT"/>
<Condition description="Alert Condition 1" used="37" phone="+46739834491" lastTrigger="2023-03-14 12:01 +1 GMT"/>
*/
export default ConditionEdit