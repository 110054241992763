import React, { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from "../context/AuthProvider"
import { login } from './Api';
import { getServices } from './Api';
function Login() {
    const { setCurrentPath } = useContext(AuthContext);
    const { auth, setAuth } = useContext(AuthContext);
    const { accountLevel, setAccountLevel } = useContext(AuthContext);
    const userRef = useRef();
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');
    const [hide,setHide] = useState(false);

    useEffect( () => {
        setError('')
        
    }, [account,password]);
    useEffect( () => {
        setHide(false);
        setError('')
        //if(hide === false){ userRef.current.focus();}
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setHide(true);
        setError('');
        try {
            const token = await login(account, password, rememberMe);
            localStorage.setItem('token', token);
            setError("");
            setAuth(true);
            
            getServices().then( (data) => {
                console.log(data);
                setAccountLevel({"services":data.services});
            }).catch( (e) => console.log);
        } catch (err) {
            
            setError(err.message);
            setHide(false);
        }
    };



    return (
        <div className="login">
            <div className="content login-page">
                <h4>Customer portal</h4>
                
                { hide === false ? (<>
                <form id="Login" onSubmit={handleSubmit}>
                    <label><div className="title">Account Name:</div>
                    <input type="text" className="textbox login" value={account} onChange={e => setAccount(e.target.value)} ref={userRef} autoComplete="off" name="email" required />
                    </label>
                    <br/>
                    <label><div className="title">Password:</div><input type="password" onChange={e => setPassword(e.target.value)} className="textbox login" name="password" /></label>
                    <br/>
                    <label className="spacing"><input type="checkbox"/> Keep me signed in</label>
                    <button className="btn-submit" type="submit">Sign in</button>
                </form>
                { error && error !== "" ? <p className="error-text">{error}</p> : ""}
                </>) : null }
            </div>
        </div>
    );
}

export default Login