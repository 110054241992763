import React from 'react'
import { Link } from "react-router-dom";
import { useContext, useState,useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { getServices } from './Api';
import AuthContext from "../context/AuthProvider"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas,far)

function Menu() {
    const location = useLocation();
    const { currentPath, setCurrentPath, auth, accountLevel, setAccountLevel } = useContext(AuthContext);
    useEffect(() => {
        setCurrentPath(location.pathname);
      }, [location.pathname]);

    useEffect( () => {
            getServices().then( (data) => {
                console.log(data);
                setAccountLevel({"services":data.services});
            }).catch( (e) => console.log);
    }, [location.pathname]);  
    
    let i = 0;
    
    return (
        <div className="menu"> { auth == true ? (<>
        <ul>
            {/*<li key="menu_dashboard_0" className={currentPath === "/" ? "active" :null }><Link to="/"><FontAwesomeIcon icon="fa-solid fa-table-columns" />Dashboard</Link></li>
            <li key="menu_account_1" className={currentPath === "/account" ? "active" :null }><Link to="/account"><FontAwesomeIcon icon="fa-solid fa-user-gear" />Account</Link></li>*/}
            <li key="menu_services_2" className={currentPath === "/services" ? "active" :null }><Link to="/services"><FontAwesomeIcon icon="fa-solid fa-layer-group" />Services</Link></li>
        </ul>
        <ul className="bottom">
        <li key="menu_title_0" className="menu-title"  >Your services</li>
        {  accountLevel.services.map( (service, index) => { return (
                <li key={`menu_service_${index}`} className={`${currentPath === `/services/${service.service_path}` ? "active" :null }`}>
                <Link to={`/services/${service.service_path}`}><FontAwesomeIcon icon={service.service_image}  />{service.service_name}</Link></li>
                ) } )}
        </ul>
        <ul className="bottom">
            <li key="menu_logout_0" className={currentPath === "/logout" ? "active" :null }><Link to="/logout"><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />Sign out</Link></li>
        </ul>
        </>) : null}</div>
    )
}

export default Menu
