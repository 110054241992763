import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense, useContext,useEffect } from 'react';
import Layout from "./pages/Layout.js";
import Landing from "./pages/Landing.js";
import Inventory from "./pages/Inventory.js";
import Logout from "./pages/Logout";
import Account from "./pages/Account";
import Caller from "./pages/Caller";
import { logout } from './pages/Api';
import ConditionEdit from "./pages/CallerConditionEdit";
import NoPage from "./pages/NoPage";
import AuthContext from "./context/AuthProvider"
import ServiceSelect from "./pages/ServiceSelect"
//import ServiceSelect from './pages/ServiceSelect.js'
import './App.css';
//const ServiceSelect = lazy(() => import('./pages/ServiceSelect.js'));

function App() {
    const { auth } = useContext(AuthContext);
       
    
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="account" element={<Account/>} />
            <Route path="services/callback" element={<Caller/>} />
            <Route path="services/inventory" element={<Inventory/>} />
            <Route path="/services/callback/edit/:uuid" element={<ConditionEdit/>} />
            <Route path="services" element={<ServiceSelect/>} />            
            <Route path="logout" element={<Logout/>} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
}
/*
<Route path="services" element={
            auth && (
              <Suspense fallback={<div>Loading...</div>}>
                <ServiceSelect/>
              </Suspense>
              )} />
              */ 
export default App;
