import React, { useState, useEffect, useRef, useContext } from 'react';
import { logout } from './Api';

const Logout = () => {

    const handleLogout = () => {
        logout();
    }
    useEffect( () => {
        handleLogout();
        window.location.href="/";
    }, []);

    return (<div className="content"></div>);
};

export default Logout;