import React from 'react'
import AuthContext from "../context/AuthProvider"
import { useContext,useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { getServices } from './Api';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas,far)
const Service = ({service}) => {
  console.log(service);
  if(service === null) {
    return <>loading</>
  } else { 
    //let bgImage = {'backgroundImage': `url(${service.service_image})`}; style={bgImage}
    return <Link className="service-select" to={"/services/"+service.service_path}>
      {service.service_image ? (<div className="img"><FontAwesomeIcon icon={service.service_image}/></div>) : null}
      <div className="text">
      <div className="title">{service.service_name}</div>
      <div className="description">{service.service_description}</div>
      </div>
    </Link>
  }
}

function ServiceSelect() {
  const [services, setServices] = useState([]);
  useEffect( () => {
      getServices().then( (data) => {
          console.log(data);
          setServices(data.services);
      }).catch( (e) => console.log);
  
  }, []);  
  return (
    <>
    <div className="top-bar">
      Select a service to view or configure its settings
    </div>
    <div className="wrap-content">
      {services?.length > 0 ? (services.map((service,i) => 
          (<Service key={(i+1)+"_document"} service={service}/>))
          ) : <Service key={0+"_document"} service={null}/>
      }
    </div>
    </>
  )
}

export default ServiceSelect